<template>
  <div class="content">
    <div class="title">{{detail.name}}</div>
    <div class="main">{{detail.content}}</div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'NoticeDetail',
  data() {
    return {
      detail: {
        name: '',
        content: ''
      }
    }
  },
  computed: {
    ...mapState({
      routes: 'route'
    })
  },
  mounted() {
    const id = this.$route.query.id * 1 || null
    if (id) {
      this.getDetail(id)
      this.hasRead(id)
    }
  },
  methods: {
    async getDetail(id) {
      const url = `admin/notice/detail?id=${id}`
      this.detail = await this.$https.get(url)
    },
    hasRead(id) {
      const url = 'admin/notice/read'
      const params = {
        notice_id: id
      }
      this.$https.post(url, params)
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  .title {
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 24px;
  }
  .main {
    width: 70vw;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
